<template>
  <v-expansion-panels accordion focusable flat hover class="my-2">
    <v-expansion-panel v-for="(item, i) in logs" :key="i">
      <v-expansion-panel-header
        expand-icon="mdi-menu-down"
        color="grey lighten-4"
      >
        <v-row no-gutters>
          <v-col>{{ item.$calculated.$createdAt }}</v-col>
          <v-col>{{ item.eventData.event }}</v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <GsEventDataView v-model="item.eventData" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import dayjs from "dayjs";
import GsEventDataView from "@/components/GsEventDataView";

export default {
  name: "MessageEventLog",
  components: {
    GsEventDataView
  },
  props: {
    messageId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      logs: []
    };
  },
  watch: {
    messageId: {
      immediate: true,
      deep: true,
      handler: async function(value) {
        await this.getLogs(value);
      }
    }
  },
  async created() {
    // await this.getLogs(this.messageId);
  },
  methods: {
    async getLogs(messageId) {
      if (!messageId) {
        this.logs = [];
        return;
      }
      try {
        const url = `${this.$store.state.email_manager_api}/logs?filter=messageId:eq:${messageId}&sort=createdAt:desc`;

        const result = await callAPI({
          url,
          method: "GET"
        });
        this.logs = [];
        for (const log of result.data) {
          await this.calculateProperties(log);
          this.logs.push(log);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async calculateProperties(log) {
      log.$calculated = log.$calculated || {};
      log.$calculated.$createdAt = this.getDisplayDate(log.createdAt);
    },
    getDisplayDate(date) {
      if (!date) {
        return "";
      }
      return dayjs(date).format("YYYY.MM.DD HH:MM:ss");
    }
  }
};
</script>

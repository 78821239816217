<template>
  <v-container class="container" fluid>
    <VueObjectView v-model="eventData" :nowrap="false" />
  </v-container>
</template>

<script>
import VueObjectView from "@/components/vue-object-view";

export default {
  name: "EventDataView",
  components: {
    VueObjectView
  },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      eventData: null
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        this.eventData = value;
      }
    }
  }
};
</script>

<style lang="scss">
.object-view {
  width: 100px;
  word-wrap: normal;
}
</style>

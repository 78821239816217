<template>
  <v-card fluid :flat="flat" :tile="tile" class="my-2">
    <GsDataTable
      ref="table"
      api="email_manager_api"
      endpoint="/messages"
      :appSetting="appSettingKey"
      :headers="headers"
      :sort-by="['createdAt']"
      :sort-desc="[true]"
      :beforeCallApi="beforeCallApi"
      :afterCallApi="afterCallApi"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      show-expand
      item-key="jobId"
      :class="extraClass"
    >
      <template v-slot:item.fileName="{ item }">
        <div>
          <v-avatar size="16px" class="mx-2">
            <v-img :src="item.$calculated.icon" contain />
          </v-avatar>
          <span>{{ item.title }} ({{ item.fileName }})</span>
        </div>
      </template>

      <template v-slot:item.createdAt="{ item }">
        <span>{{ item.createdAt | getDisplayDate }}</span>
        <br />
        <span> ({{ item.$calculated.$createdBy | empty }})</span>
      </template>

      <template v-slot:item.updatedAt="{ item }">
        <span>{{ item.updatedAt | getDisplayDate }}</span>
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip :color="getStatusColor(item.status)" dark>{{
          $t(item.status.split(".").pop())
        }}</v-chip>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <GsMessageEventLog :messageId="item.messageId" />
        </td>
      </template>
    </GsDataTable>
  </v-card>
</template>

<script>
import GsMessageEventLog from "@/components/GsMessageEventLog";
import { GsDataTable } from "ngt-frontend-core";

export default {
  name: "MessageLog",
  components: {
    GsMessageEventLog,
    GsDataTable
  },
  props: {
    appSettingKey: {
      type: String,
      default: null
    },
    jobId: {
      type: String,
      default: null
    },
    templateId: {
      type: String,
      default: null
    },
    flat: {
      type: Boolean,
      default: false
    },
    tile: {
      type: Boolean,
      default: false
    },
    extraClass: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("createdAt"),
          value: "createdAt",
          sortable: true,
          width: "20%"
        },
        {
          text: this.$t("addressFrom"),
          value: "addressFrom",
          sortable: true,
          width: "30%"
        },
        {
          text: this.$t("addressTo"),
          value: "addressTo",
          sortable: true,
          width: "20%"
        },
        {
          text: this.$t("subject"),
          value: "subject",
          sortable: true,
          width: "10%"
        },
        {
          text: this.$t("status"),
          value: "status",
          sortable: true,
          width: "10%",
          align: "center"
        },
        {
          text: this.$t("updatedAt"),
          value: "updatedAt",
          sortable: true,
          width: "15%"
        },
        {
          text: this.$t("events"),
          value: "data-table-expand",
          sortable: false,
          width: "5%",
          align: "right"
        }
      ],
      expanded: [],
      singleExpand: true,
      initialized: false
    };
  },
  watch: {
    jobId() {
      this.refreshTable();
    },
    templateId() {
      this.refreshTable();
    }
  },
  mounted() {
    this.initialized = false;
    this.$nextTick(() => {
      this.initialized = true;
    });
  },
  methods: {
    async refreshTable() {
      try {
        await this.$refs.table.refreshTable();
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async beforeCallApi(params) {
      if (this.jobId != null) {
        params.url += `&filter=jobId:eq:${this.jobId}`;
      }

      if (this.templateId != null) {
        params.url += `&filter=templateId:eq:${this.templateId}`;
      }
      params.url += `&fields=messageId,templateId,jobId,addressFrom,addressTo,subject,attachments,status,referenceId,createdAt,createdBy,updatedAt`;
      params.url += `&r8sFields=createdBy.name,updatedBy.name`;
      return params;
    },
    async afterCallApi(params) {
      for (const item of params.items) {
        item.$calculated = item.$calculated || {};
        item.$calculated.$createdBy = item.createdBy?.name;
        item.$calculated.$updatedBy = item.updatedBy?.name;
      }
      return params;
    }
  }
};
</script>
